import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';

const MessageConverter = () => {
  const [file, setFile] = useState(null);
  const [msg, setMsg] = useState('');
  const [outputFormat, setOutputFormat] = useState('');

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    setOutputFormat(fileExtension === 'csv' ? 'csv' : 'xlsx');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleMsgChange = (e) => {
    setMsg(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file || !msg) {
      alert('Please select a file and enter a message.');
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      const modifiedData = excelData.map((row) => {
        const { PhoneNumber, MakeModel, CustomerName, RegNo } = row;
        const modifiedMsg = msg
          .replace(/CustomerName/g, CustomerName)
          .replace(/RegNo/g, RegNo)
          .replace(/MakeModel/g, MakeModel);

        return {
          PhoneNumber  :PhoneNumber,
          message: modifiedMsg,
        };
      });

      if (outputFormat === 'csv') {
        const csvData = XLSX.utils.json_to_sheet(modifiedData);
        const csvContent = XLSX.utils.sheet_to_csv(csvData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', 'converted_data.csv');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else if (outputFormat === 'xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'converted_data.xlsx');
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className='file-form'>
      <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? 'active' : ''}`}
          style={{
            borderColor: isDragActive ? '#00e676' : '#ddd',
            padding: '30px',
            textAlign: 'center',
            borderWidth: '2px',
            borderStyle: 'dashed',
            borderRadius: '4px',
            backgroundColor: '#fafafa',
            color: '#888',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            minHeight: '200px', // Set your desired height here
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input {...getInputProps()} accept="*" />
          <p>{file ? file.name : 'Drop files here or click to select files'}</p>
        </div>
        <textarea
          placeholder="Enter message template"
          value={msg}
          onChange={handleMsgChange}
          className="messageTextArea"
        />
         <button type="submit" className="submitButton">Convert & Download</button>

      </form>
    </div>
  );
};

export default MessageConverter;
