import React, { useRef } from "react";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import About from "../Components/About/About";
import Fact from "../Components/Fact/Fact";
import Services from "../Components/Services/Services";
import Contact from "../Components/Contact/Contact";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  const aboutRef = useRef(null);
  const heroRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const objRef = {
    heroRef: heroRef,
    servicesRef: servicesRef,
    aboutRef: aboutRef,
    contactRef: contactRef
  }

  const scrollToSection = (ref) => {
    const elementRef = objRef[ref] || heroRef;
    const offset = elementRef.current.offsetTop -100;
    window.scrollTo({ behavior: "smooth" , top: offset});
  };

  return (
    <div>
      <Header scrollToSection={scrollToSection} />
      <HeroSection ref={heroRef} />
      <About ref={aboutRef} />
      <Fact />
      <Services ref={servicesRef} />
      <Contact ref={contactRef}/>
      <Footer />
    </div>
  );
};

export default Home;
