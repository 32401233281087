import React, { useEffect } from "react";
import "./App.css";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WOW from "wowjs";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageConverter from "./Pages/Message";

function App() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/converter" component={MessageConverter} /> {/* Define your route for the new component */}
          {/* Add more routes as needed */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
