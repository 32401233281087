import React, { useState } from "react";

const Header = ({ scrollToSection }) => {
  const [activeNavItem, setActiveNavItem] = useState("home");

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
      <a
        href="#top"
        className="navbar-brand d-flex align-items-center px-4 px-lg-5"
      >
        <h2 className="m-0 text-primary">
          <i className="fa fa-car me-3"></i>Autovision Solutions
        </h2>
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a
            href="#Home"
            className={`nav-item nav-link ${
              activeNavItem === "home" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("homeRef");
              handleNavItemClick("home");
            }}
          >
            Home
          </a>
          <a
            href="#About"
            className={`nav-item nav-link ${
              activeNavItem === "about" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("aboutRef");
              handleNavItemClick("about");
            }}
          >
            About
          </a>
          <a
            href="#Services"
            className={`nav-item nav-link ${
              activeNavItem === "services" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("servicesRef");
              handleNavItemClick("services");
            }}
          >
            Services
          </a>
          <a
            href="#Contact"
            className={`nav-item nav-link ${
              activeNavItem === "contact" ? "active" : ""
            }`}
            onClick={() => {
              scrollToSection("contactRef");
              handleNavItemClick("contact");
            }}
          >
            Contact
          </a>
        </div>
        <a
          href="#Contact"
          onClick={() => scrollToSection("contactRef")}
          className="btn btn-primary py-4 px-lg-5 d-none d-lg-block"
        >
          Get A Quote<i className="fa fa-arrow-right ms-3"></i>
        </a>
      </div>
    </nav>
  );
};

export default Header;
