import React, { forwardRef } from "react";

const Services = forwardRef((props, ref) => {
  return (
    <div className="container-xxl service py-5" ref={ref}>
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-primary text-uppercase">{"// Our Services //"}</h6>
          <h1 className="mb-5">Explore Our Services</h1>
        </div>
        <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-4">
            <div className="nav w-100 nav-pills me-4">
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1"
                type="button"
              >
                <i className="fa-solid fa-car-wash"></i>
                <i className="fa fas fa-wrench fa-2x me-3"></i>
                <h4 className="m-0">Routine Vehicle Servicing</h4>
              </button>

              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-3"
                type="button"
              >
                <i className="fa fa-shower fa-2x me-3"></i>
                <h4 className="m-0">Washing and Lubrication</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-4"
                type="button"
              >
                <i className="fa fa-cogs fa-2x me-3"></i>
                <h4 className="m-0">Complete Care AC Service</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-4"
                type="button"
              >
                <i className="fa fa-car fa-2x me-3"></i>
                <h4 className="m-0">Pick & Drop Facility</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2"
                type="button"
              >
                <i className="fa fa-tools fa-2x me-3"></i>
                <h4 className="m-0">Wheel Alignment & Wheel Balancing</h4>
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="nav w-100 nav-pills me-4">
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1"
                type="button"
              >
                <i className="fa fas fa-gas-pump fa-2x me-3"></i>
                <h4 className="m-0"> Fuel Injector Cleaning </h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2"
                type="button"
              >
                <i className="fa fas fa-fire fa-2x me-3"></i>
                <h4 className="m-0"> Engine De-Carbonizing</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-3"
                type="button"
              >
                <i className="fa fas fa-battery-full fa-2x me-3"></i>
                <h4 className="m-0"> Battery Service</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2"
                type="button"
              >
                <i className="fa fa-car-side fa-2x me-3"></i>
                <h4 className="m-0">Car Scanning</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-4"
                type="button"
              >
                <i className="fa fas fa-car-battery fa-2x me-3"></i>
                <h4 className="m-0">
                  Vehicle Battery, Alternator & Starter Service
                </h4>
              </button>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="nav w-100 nav-pills me-4">
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 "
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1"
                type="button"
              >
                <i className="fa fas fa-lightbulb fa-2x me-3"></i>
                <h4 className="m-0"> Lighting & Beam Focusing</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2"
                type="button"
              >
                <i className="fa fas fa-tshirt fa-2x me-3"></i>
                <h4 className="m-0"> Car Polishing & Dry Cleaning</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-3"
                type="button"
              >
                <i className="fa fas fa-shield-alt fa-2x me-3"></i>
                <h4 className="m-0"> Anti-Rust Treatment</h4>
              </button>
              <button
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-4"
                type="button"
              >
                <i className="fa fas fa-car-crash fa-2x me-3"></i>
                <h4 className="m-0">Painting and Accident Jobs</h4>
              </button>

              <button
                style={{ height: "106px" }}
                className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1"
                type="button"
              >
                <i className="fa fas fa-cogs fa-2x me-3"></i>
                <h4 className="m-0">Brake & Gear Box Service</h4>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Services;
