import React, { forwardRef } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from 'swiper';
import CarouselBackground1 from "../../Assets/Images/carousel-bg-1.jpg";
import CarouselImage1 from "../../Assets/Images/carousel-1.png";
import CarouselBackground2 from "../../Assets/Images/carousel-bg-2.jpg";
import CarouselImage2 from "../../Assets/Images/carousel-2.png";

const HeroSection = forwardRef((props, ref) => {
  return (
    <div className="container-fluid p-0 mb-5" ref={ref}>
      <div
        id="header-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <Swiper
            spaceBetween={0}
            loop={true}
            speed={1000}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={{
              nextEl: ".carousel-control-next",
              prevEl: ".carousel-control-prev",
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="carousel-item active">
                <img className="w-100" src={CarouselBackground1} alt="carosuelimage" />
                <div className="carousel-caption d-flex align-items-center">
                  <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-lg-start">
                      <div className="col-10 col-lg-7 text-center text-lg-start">
                        <h6 className="text-white text-uppercase mb-3 animated slideInDown">
                        {"// Autovision Solutions //"}
                        </h6>
                        <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">
                        Your One-Stop Solution for Car Repair and Service
                        </h1>
                        <a
                          href="#home"
                          className="btn btn-primary py-3 px-5 animated slideInDown"
                        >
                          Learn More<i className="fa fa-arrow-right ms-3"></i>
                        </a>
                      </div>
                      <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                        <img
                          className="img-fluid"
                          src={CarouselImage1}
                          alt="carouselimage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="carousel-item active">
                <img className="w-100" src={CarouselBackground2} alt="carouselImage" />
                <div className="carousel-caption d-flex align-items-center">
                  <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-lg-start">
                      <div className="col-10 col-lg-7 text-center text-lg-start">
                        <h6 className="text-white text-uppercase mb-3 animated slideInDown">
                        {"// Autovision Solutions Car Wash //"}
                        </h6>
                        <h1 className="display-3 text-white mb-4 pb-3 animated slideInDown">
                        Where Cleanliness Meets Precision
                        </h1>
                        <a
                          href="#home"
                          className="btn btn-primary py-3 px-5 animated slideInDown"
                        >
                          Learn More<i className="fa fa-arrow-right ms-3"></i>
                        </a>
                      </div>
                      <div className="col-lg-5 d-none d-lg-flex animated zoomIn">
                        <img
                          className="img-fluid"
                          src={CarouselImage2}
                          alt="carouselimage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
});

export default HeroSection;
