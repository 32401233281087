import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  const email = "Avsservicefbd@gmail.com";

  const address =
    "AutoVision Solutions(Bosch Car Authorised Service Center), 14/5 Jainco complex near mewala Maharajpur Metro station pillar no 573, Faridabad, Haryana 121003";

  const openMap = () => {
    const formattedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
    window.open(googleMapsUrl, "_blank");
  };

  const openMail = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Address</h4>
            <p className="mb-2 cursor-pointer" onClick={openMap}>
              <i className="fa fa-map-marker-alt me-3"></i>14/5 Jainco complex
              <br />
              Faridabad, Haryana 121003
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              +91-8929903767,+91-8929903768
            </p>
            <p className="mb-2 cursor-pointer" onClick={openMail}>
              <i className="fa fa-envelope me-3 "></i>Avsservicefbd@gmail.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="#home">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="#home">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="#home">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="#home">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Opening Hours</h4>
            <h6 className="text-light">Sunday - Monday:</h6>
            <p className="mb-4">09.30 AM - 06.30 PM</p>
            <h6 className="text-light">Lunch Hours:</h6>
            <p className="mb-0">01.00 PM - 01.30 PM</p>
          </div>
          <div className="col-lg-6 col-md-6">
            <h4 className="text-light  mb-4">Services</h4>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Routine Vehicle Servicing
              </a>
              <a className="btn btn-link" href="#home">
                Washing and Lubrication
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Wheel Alignment & Wheel Balancing
              </a>
              <a className="btn btn-link" href="#home">
                Engine De-Carbonizing
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Fuel Injector Cleaning
              </a>
              <a className="btn btn-link" href="#home">
                Engine De-Carbonizing
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Anti-Rust Treatment
              </a>
              <a className="btn btn-link" href="#home">
                Painting and Accident Jobs
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Car Scanning
              </a>
              <a className="btn btn-link" href="#home">
                Battery Service
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Painting and Accident Jobs
              </a>
              <a className="btn btn-link" href="#home">
                Car Polishing & Dry Cleaning
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Pick & Drop Facility
              </a>
              <a className="btn btn-link" href="#home">
                Complete Care AC Service
              </a>
            </div>
            <div className="service-link">
              <a className="btn btn-link" href="#home">
                Vehicle Battery, Alternator & Starter Service
              </a>
              <a className="btn btn-link" href="#home">
                Lighting & Beam Focusing
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <span style={{ fontSize: "20px" }}>&copy;</span>
              <a className="border-bottom" href="#home">
                {" "}
                {year} Autovision Solutions
              </a>{" "}
              All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="#home">Home</a>
                <a href="#home">Cookies</a>
                <a href="#home">Help</a>
                <a href="#home">FAQs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
