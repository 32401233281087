import React, { forwardRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value starts with a '+' (optional) and is followed by digits, and total length is less than or equal to 12
    if (/^(\+)?\d*$/.test(value) && value.length <= 13) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, message } = formData;
    if (!name || !phone || !message) {
      let errorMessage = "";

      if (!name) {
        errorMessage = "Error! Please enter the required field: Name.";
      } else if (!phone) {
        errorMessage = "Error! Please enter the required field: Phone.";
      } else if (!message) {
        errorMessage = "Error! Please enter the required field: Message.";
      }

      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://7fzgnu2dw7.execute-api.ap-south-1.amazonaws.com/dev/get-message",
        JSON.stringify({ name, phone, message }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Success! Request sent successfully.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      console.log(response);
      // Handle success, show a success message or redirect
    } catch (error) {
      setLoading(false);
      toast.error("Error! While processing your request.", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error(error);
      // Handle error, show an error message
    }
  };

  return (
    <div
      className="container-fluid bg-secondary booking my-5 wow fadeInUp"
      ref={ref}
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6 py-5">
            <div className="py-5">
              <h1 className="text-white mb-4">
                Bosch Authorized Car Service Center
              </h1>
              <p className="text-white mb-0">
                Autovision Solution offers simple automobile service booking.
                Our skilled team provides a simple approach for arranging
                routine maintenance, tyre alignment, AC service, and other
                services. With our high-quality service, you can rely on us to
                keep your car in good condition.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="bg-primary h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn"
              data-wow-delay="0.6s"
            >
              <h1 className="text-white mb-4">Contact Us</h1>
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="form-control border-0"
                      placeholder="Your Name"
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="form-control border-0"
                      placeholder="ex. 9999999999"
                      maxLength={15}
                      style={{ height: "55px" }}
                    />
                  </div>
                  <div className="col-12 col-sm-6"></div>

                  <div className="col-12">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="form-control border-0"
                      placeholder="Your Query"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-secondary w-100 py-3 d-flex justify-content-center align-items-center cursor-pointer"
                      type="submit"
                    >
                      Submit
                      {loading && (
                        <div
                          class="spinner-border text-light"
                          role="status"
                          style={{
                            marginLeft: "5px",
                            height: "15px",
                            width: "15px",
                          }}
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contact;
