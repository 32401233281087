import React, { forwardRef } from 'react';
import AboutImg from "../../Assets/Images/about.jpg"

const About = forwardRef((props, ref) => {
  return (
    <div className="container-xxl py-5" ref={ref}>
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 pt-4"  style={{minHeight: "400px"}}>
            <div
              className="position-relative h-100 wow fadeIn"
              data-wow-delay="0.1s"
            >
              <img
                className="position-absolute img-fluid w-100 h-100"
                src={AboutImg}
                style={{objectFit: "cover"}}
                alt="aboutimg"
              />
              <div
                className="position-absolute top-0 end-0 mt-n4 me-n4 py-4 px-5"
                style={{background: "rgba(0, 0, 0, .08)"}}
              >
                <h1 className="display-4 text-white mb-0">
                  15 <span className="fs-4">Years</span>
                </h1>
                <h4 className="text-white">Experience</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h6 className="text-primary text-uppercase">{"// About Us //"}</h6>
            <h1 className="mb-4">
              <span className="text-primary">Autovision Solutions</span> Is The Best Place For
              Your Auto Care
            </h1>
            <p className="mb-4">
            Autovision Solutions is your dependable source for high-quality auto repair and service. Our expert team and cutting-edge facilities provide dependable solutions for all of your automobile requirements.
            </p>
            <div className="row g-4 mb-3 pb-3">
              <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                <div className="d-flex">
                  <div
                    className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                    style={{width: "45px", height:"45px"}}
                  >
                    <span className="fw-bold text-secondary">01</span>
                  </div>
                  <div className="ps-3">
                    <h6>Professional & Expert</h6>
                    <span>Trust our qualified professionals for expert car servicing and repair.</span>
                  </div>
                </div>
              </div>
              <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                <div className="d-flex">
                  <div
                    className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                    style={{width: "45px", height:"45px"}}
                  >
                    <span className="fw-bold text-secondary">02</span>
                  </div>
                  <div className="ps-3">
                    <h6>Quality Servicing Center</h6>
                    <span>At our servicing centre, you will receive excellent care and attention to detail.</span>
                  </div>
                </div>
              </div>
              <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                <div className="d-flex">
                  <div
                    className="bg-light d-flex flex-shrink-0 align-items-center justify-content-center mt-1"
                    style={{width: "45px", height:"45px"}}
                  >
                    <span className="fw-bold text-secondary">03</span>
                  </div>
                  <div className="ps-3">
                    <h6>Affordable Prices</h6>
                    <span>Get professional car maintenance and repairs at affordable prices without sacrificing quality.</span>
                  </div>
                </div>
              </div>
            </div>
            <a href="#Contact" className="btn btn-primary py-3 px-5">
              Contact Now<i className="fa fa-arrow-right ms-3"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

export default About;
